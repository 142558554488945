// @requires
// mixins/media-query.scss
// functions/rems.scss


//----------------------------------------------------- module - general

.card {
  padding: 0.25rem 1rem;
  font-size: context-calc(14, $font-base, em);
  line-height: 1.425;
}


//----------------------------------------------------- module - single

.card-single {
  color: inherit;
  @include mq(min, 640px) {
    display: flex;
    justify-content: center;
    align-items: stretch;
    align-content: center;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover * {
    @include mq(min, 640px) {
      background: $card-single-hover-bg;
      color: $card-single-hover-color;
    }
  }
}


.card-single > div {
  @include mq(min, 640px) {
    flex: 100%;
  }
}


.flexboxtweener .card-single > div {
  @include mq(min, 640px) {
    flex: 1 1 auto;
  }
}


.card-single > * ~ * {
  @include mq(min, 640px) {
    border-left: 4px solid $content-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 8.75em; // 140px
    width: 100%;
    background: $card-single-bg;
  }
}

.card-single:hover .card {
    background: $card-single-hover-bg;
    color: $card-single-hover-color;
}


.card--reply {
  position: relative;
  color: black;
  background: #EBEBEB;
  z-index: 0;

  &::before {
    border-left: 6px solid #fe0076;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
}



//----------------------------------------------------- color variations

.card--gallery {
  background: $card-gallery;
  color: $card-gallery-fontcolor;
}


.card--gallery.hover-rose:hover {
  cursor: pointer;
  @extend .card--rose;
}


.card--plumb {
  background: $card-plumb;
  color: $card-plumb-fontcolor;
}


.card--gold {
  background: $card-gold;
}


.card--rose {
  background: $card-rose;
  color: $card-rose-fontcolor;
}

// .card--rose a {
//   color: #333346;
// }


.card--carulean {
  background: $card-cerulean;
  color: $card-cerulean-fontcolor;
}
