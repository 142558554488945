.teacher-status {
  font-size: 1.5em;
}


.teacher-status .avatar {
  border: context-calc(5, 16 * 1.5, em) solid #fe0076;
}


.teacher-status .media-obj__body {
  margin-top: 0.5em;
  text-align: center;
}


.teacher-status .status {
  @extend .material-shadow;
  margin-bottom: 0.5em;
  padding: 0.5em;
  background: $status-bg;
}


.teacher-status .winner {
  &::before {
    padding-bottom: 0.5rem;
  }
}
