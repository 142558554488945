//----------------------------------------------------- module

.news-report {
  &:hover,
  &:focus {
    @include mq(min, 640px) {
      background: $rose;
    }
  }

  &:not(:first-of-type) {
    margin-top: 1rem;
  }

  &:last-of-type {
    margin-bottom: 1rem;
  }
}



//----------------------------------------------------- direct children

.news-report > * {
  &:hover,
  &:focus {
    @include mq(min, 640px) {
      background: $rose;
      color: white;
    }
  }
}



//----------------------------------------------------- direct child sibling

.news-report > *:hover ~ * {
  @include mq(min, 640px) {
    background: $rose;
    color: white;
  }
}
