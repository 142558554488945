// @requires
// mixins/media-query.scss
// functions/rems.scss

//----------------------------------------------------- component

.comment {
  position: relative;
  color: #3F0C54;
}


.comments .card {
  padding: 1.5rem 2rem;
}



//----------------------------------------------------- layout : comment body children

.comment .media-obj__body > *:first-child {
  margin-top: 0;
}


.comment .media-obj__body a {
  font-weight: bold;
  color: #390050;
  &:hover,
  &:focus {
    color: $rose;
  }
}


//----------------------------------------------------- layout : comment figure

.comment + .comment {
  margin-top: 1rem;
}


.comments .comment .card .media-obj__fig {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: rems(20);
}


.comments .comment .card .media-obj__fig img {
  margin-bottom: rems(15);
}



//----------------------------------------------------- child comments

.comment-level1 .card {
  position: relative;
  background: transparent;
  @include mq(min, $comments-lg) {
    padding-left: rems((28 * 4));
    background: $comment-level1-cardbg;
  }
}


.comment-level2 .card {
  background: $comment-level2-cardbg;
  @include mq(min, $comments-lg) {
    padding-left: rems((28 * 6.75));
  }
}


.comment-level2 {
  position: relative;
  padding: rems(10);
  padding-bottom: rems(5);

  @include mq(min, $comments-lg) {
    padding: 0;
    padding-bottom: 0;
  }

  &:not(:last-of-type) {
    padding-bottom: 0;
    @include mq(min, $comments-lg) {
      padding-bottom: initial;
    }
  }

  @include mq(min, $comments-lg) {
    padding: initial;
  }
}


.comments-level1,
.comments-level2 {
  position: relative;

  &::before {
    @include mq(min, $comments-lg) {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background: #9E86A7;
      position: absolute;
      top: -15px;
      bottom: 20px;
      z-index: 1;
    }
  }
}


.comments-level1 {
  border: rems(20) solid $comment-level1-border-color;
  background: $comment-level1-bg;
  padding-bottom: rems(5);
  @include mq(min, $comments-lg) {
    border: none;
    padding-bottom: inherit;
  }

  &::before {
    @include mq(min, $comments-lg) {
      left: rems(60);
    }
  }
}


.comments-level2 {
  border: none;
  &::before {
    @include mq(min, $comments-lg) {
      left: rems((28 * 5));
    }
  }
}


.comment-child2.comment-level2:before {
  @include mq(min, $comments-lg) {
    content: '';
    display: block;
    height: 100%;
    width: 2px;
    position: absolute;
    top: -16px;
    left: rems((32 * 6.75));
    right: 0;
    z-index: 0;
    background: #9e86a7;
  }
}



//----------------------------------------------------- layout : users comments

.user-comments__response .card {
  padding: 1.5rem 2rem;
}


.user-comments__response .card .media-obj__fig {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: rems(15);
}


.user-comments__response .card .media-obj__fig img {
  margin-bottom: rems(20);
}


.user-comments__response .card .media-obj__body >:first-child {
  margin-top: 0;
}
