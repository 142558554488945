// large
.tinynav {
  display: none;
}


// small
@media screen and (max-width: 768px) {
  .tinynav {
    display: block;
  }

  .select-nav {
    display: none;
  }
  .no-js .select-nav {
    display: block;
    font-size: 0;
    li {
        display: inline-block;
        width: 50%;
        font-size: 16px;
    }
  }
}
