.nav-dropdown, .menu-header-menu-container {
  background: rgba($nav-dropdown-bg, 0.9);
  @include mq(min, $max-mq) {
    position: relative;
    // transform: scale(1);
    font-size: 1em;
    text-align: left;
    background: $nav-dropdown-bg;
  }
}

.js .nav-dropdown, .js .menu-header-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  // transition: 235ms all cubic-bezier(0.47, 0.02, 0.46, 1);
  text-align: right;
  font-size: 1.25em;
  z-index: 1;
  @include mq(min, $max-mq) {
    position: relative;
    transform: scale(1);
    font-size: 1em;
    text-align: left;
    background: $nav-dropdown-bg;
  }
}

.no-js .nav-dropdown, .no-js .menu-header-menu-container {
    font-size: 0.8em;
    @include mq(min, $max-mq) {
        font-size: 1em;
    }
}

.nav-dropdown__list, .menu-header-menu-container ul {
  height: 100%;
  font-size: 1em;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  @include mq(min, $max-mq) {
    display: block;
  }
}

.menu-header-menu-container ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
}

.nav-dropdown__list li, .menu-header-menu-container ul li {
  display: block;
  list-style: none;
  @include mq(min, $max-mq) {
    display: inline-block;
  }
}


.nav-dropdown__list > li, .menu-header-menu-container > ul > li {
  &:not(:first-of-type) {
    margin-left: context-calc(-5, 16, em);
  }
}

.no-js .nav-dropdown__list > li:not(:first-of-type), .no-js .menu-header-menu-container > ul > li:not(:first-of-type) {
    margin-left: 0;
}


.nav-dropdown__list .button-dropdown, .menu-header-menu-container ul li {
  position: relative;
}


.nav-dropdown__list .button-dropdown:hover > ul,
.nav-dropdown__list .button-dropdown:focus > ul,
.menu-header-menu-container ul li:hover ul,
.menu-header-menu-container ul li:focus ul {
  display: block;
}


.nav-dropdown__list a, .menu-header-menu-container ul a {
  display: block;
  padding: context-calc(10, 16, em) context-calc(15, 16, em);
  text-decoration: none;
  background-color: transparent;
  color: $nav-dropdown-color;
  @include mq(min, $max-mq) {
    padding: context-calc(10, 16, em) context-calc(15, 16, em);
  }
}
.menu-item-has-children ul a {
    background: $nav-dropdown-bg;
}


.nav-dropdown__list a:hover,
.nav-dropdown__list a:focus,
.menu-header-menu-container a:hover,
.menu-header-menu-container a:focus {
  outline: none;
  background-color: $nav-dropdown-hover-bg;
  color: $nav-dropdown-color;
}


.nav-dropdown__list .dropdown-icon {
  margin-left: 2px;
  // transition: 0.275s all ease-in-out;
  fill: $nav-dropdown-caret-color;
}


.nav-dropdown__list a.dropdown-toggle.active .dropdown-icon {
  fill: $nav-dropdown-caret-color;
}


.nav-dropdown__list .dropdown-menu, .menu-item-has-children ul {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: right;
  background: rgba($nav-dropdown-bg, 0.6);
  color: $nav-dropdown-color;
  @include mq(min, $max-mq) {
    display: none;
    position: absolute;
    left: 0;
    width: 150%;
    z-index: 500000; // make this better
    text-align: left;
    background: $nav-dropdown-bg;
  }
}

.no-js .nav-dropdown__list .dropdown-menu, .no-js .menu-item-has-children ul {
    text-align: left;
    li a {
        padding-left: 20px;
        @include mq(min, $max-mq) {
            padding-left: 10px;
        }
    }
}

.nav-dropdown__list .dropdown-menu li, .menu-item-has-children ul li {
  min-width: 100%;
  white-space: nowrap;
}


.nav-dropdown__list .dropdown-menu a, .menu-item-has-children ul li a {
  display: inline-block;
  width: 100%;
  color: $nav-dropdown-color;
}
