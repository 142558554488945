.curriculum-vitae {
  font-size: 1em; // 16px
}


.curriculum-vitae__header {
  border-bottom: 1px solid rgba(204, 204, 204, 0.64);
	box-shadow: inset 0 1px 1px rgba(255, 251, 251, 0.76);
  padding: 2em;
  background: $curriculum-vitae-header-bg;
}


.curriculum-vitae__title {
  font-size: context-calc(32, 16, em);
}


.curriculum-vitae__header img[class*="avatar"] {
  margin-right: 1em;
}


.curriculum-vitae__body {
  padding: 2em;
  background: $curriculum-vitae-body-bg;
}


.curriculum-vitae__quote {
  display: inline-block;
  position: relative;
  font-size: 1.25em;
  margin-top: 0;
  padding: 10px 30px;
  background: rgba($ripe-plumb, 0.3);
  color: white;
  border-radius: 1px;
  &:before {
    content: "“";
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 3em;
    line-height: 1;
  }
  &:after {
    position: absolute;
    top: 5px;
    right: 5px;
    content: "”";
    font-size: 3em;
    margin-left: 5px;
    line-height: 1;
  }
}


.curriculum-vitae__body img {
  @extend .material-shadow;
  border: context-calc(5, 16, em) solid rgba(white, 0.5);
}


.curriculum-vitae li {
  &:not(:first-of-type) {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}


.curriculum-vitae__data > li {
  &:not(:first-of-type) {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}

.curriculum-vitae__data li {
    overflow: hidden;
    .speech-bubble {
        width: 47.5%;
        float: left;
        margin-right: 5%;
        @include mq(max, 500px) {
          width: 100%;
          margin-right: 0;
        }
        &:nth-child(even) {
            margin-right: 0;
        }
        h5 { font-size: 1.25em; }
        p {
            font-style: italic;
        }
    }
}

.curriculum-vitae .hide {
  display: none;
  &:target {
    display: block;
  }
}


.curriculum-vitae .read-more {
  &:hover,
  &:focus {
    color: black;
  }
}


.curriculum-vitae .shortlinks {
  padding-top: 1em;
}


.cv .card {
  margin-bottom: 1em;
  background: $curriculum-vitae-info-bg;
}


.cv .card li {
  margin-top: 1em;
  &:not(:first-of-type) {
    margin-bottom: 1em;
  }
}
