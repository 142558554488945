// @requires
// mixins/size.scss

//----------------------------------------------------- site icons

.icon {
  display: inline-block;
  @include size(36px, 36px);
  border-radius: 80%;
  padding: 0;
  position: relative;
  vertical-align: sub;
  // fill: $icon-fill;
  background: $icon-bg;
  text-align: center;
}

.icon i {
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    line-height: 36px;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    text-align: center;
    color: white;
    font-size: 16px;
    &.fa-comment {
        top: -1px;
    }
    &:before {
        margin: 0;
        padding: 0;
    }
}



//----------------------------------------------------- caret icon

.dropdown-icon {
  @include size(20px, 20px);
  vertical-align: sub;
}



//----------------------------------------------------- heart icon

.icon + .icon-heart {
    margin-top: 10px;
}

.icon-heart {
  @include size(30px, 30px);
  display: inline-block;
  position: relative;
  top: 0;
  transform: rotate(-45deg) scale(0.75);
  transform-origin: 50% 50%;
  font-size: context-calc(20, 16, em);
  line-height: 1.125;
  background-color: white;

  &::before,
  &::after {
    @include size(30px, 30px);
    border-radius: 50%;
    content: "";
    position: absolute;
    background-color: white;
  }

  &::before {
    top: -15px;
    left: 0;
  }

  &::after {
    left: 15px;
    top: 0;
  }

  &:hover {
    cursor: pointer;
  }
}


// hearted count value
.icon-heart span {
  transform: rotate(45deg);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  color: #390050;
}



//----------------------------------------------------- state

.icon-heart.js-hearted,
.icon-heart.js-hearted::before,
.icon-heart.js-hearted::after {
  background-color: #ff7e7e;
}


.icon-heart.js-hearted {
  filter: drop-shadow(-1px 1px 2px rgba(200, 0, 0, 0.9));
}


.icon-heart.js-hearted span {
  font-weight: 800;
  color: #482828;
}



//----------------------------------------------------- color variations

.icon-heart--rose {
  &::before,
  &::after {
    background: $rose;
  }
}


.icon-heart--plumb {
  background: $ripe-plumb;
  &::before,
  &::after {
    background: $ripe-plumb;
  }
}


.icon-heart--plumb span {
  color: white;
}
